<template>
   <v-carousel
    cycle
    :height="viewHeight + 'px'"
    show-arrows-on-hover
    :show-arrows="resources.length > 1"
    hide-delimiters=""
  >
    <v-carousel-item
      v-for="(resource, i) in resources"
      :key="i"
    >
      <v-layout fill-height="" column="">
        <v-flex/>
        <v-flex shrink>
          <vue-plyr >
              <video autoplay :src="resource.url" style="height:775px">
                  <source  :src="resource.url" :type="(resource.mimeType != null) ? resource.mimeType : 'video/mp4'" size="200px">
              </video>
          </vue-plyr>

        </v-flex>
        <v-flex/>
      </v-layout>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    name: 'VideoResourcesView',
    components: {
    },
    props: {

      resources : Array,
      viewHeight : 576 

    },
    computed: {
    },

    mounted () {

    },
    data: () => ({

    }),
    methods: {



    },
    watch: {
      
    }
  }
</script>
