import router from '../router/index';

export const SessionState = {
    namespaced: true,
    state: {
        packageid: '',
        currentStage: null,
        package: {},
        activeBabylonPanels: [
            {
                id: 'test',
                engine: {},
                scene: {},
                canvas: {},
            },
        ],
        showMainLoadScreen: true,
        showFadeout: false,
        desiredWaypoint: '',
        activeWaypointid: '',
        jumpToWaypointid: '',
        isProductFocused: false,
        focusedProductData: null,
        showShoppingCart: false,
    },

    mutations: {
        SetFocusedProductData(state, focusedProduct) {
            state.focusedProductData = focusedProduct;
        },
        SetShoppingCartVisibility(state, showShoppingCart) {
            state.showShoppingCart = showShoppingCart;
        },
        SetProductFocus(state, isFocused) {
            state.isProductFocused = isFocused;
        },
        SetActiveWaypointid(state, waypointid) {
            state.activeWaypointid = waypointid;
        },
        SetDesiredWaypoint(state, waypointid) {
            state.desiredWaypoint = waypointid;
        },
        SetJumpToWaypoint(state, waypointid) {
            state.jumpToWaypointid = waypointid;
        },
        SetFadeoutState(state, isShowing) {
            state.showFadeout = isShowing;
        },
        SetMainLoadScreenState(state, isShowing) {
            state.showMainLoadScreen = isShowing;
        },
        SetStageData(state, stagePayload) {
            state.currentStage = stagePayload;
            state.currentStageData = stagePayload.typeData;
        },
        AddNewBabylonInstance(state, newInstancePayload) {
            console.log('Adding new babylon instance');

            state.activeBabylonPanels.push({
                id: newInstancePayload.id,
                engine: newInstancePayload.engine,
                scene: newInstancePayload.scene,
                canvas: newInstancePayload.canvas,
            });
        },
        RemoveBabylonInstance(state, id) {
            console.log('Removing babylon instance ' + id);

            for (let i = 0; i < state.activeBabylonPanels.length; i++) {
                if (state.activeBabylonPanels[i].id == id) {
                    state.activeBabylonPanels.splice(i);
                    break;
                }
            }
        },
        SetPackageData(state, packagePayload) {
            state.package = packagePayload.data;
            state.packageid = packagePayload.id;
        },
    },
    actions: {
        FindNextStage({ dispatch, state }) {
            console.log('Finding next stage');
            if (state.currentStage == null) {
                console.log('stages', state.package.stages);

                dispatch('BeginStage', state.package.stages[0]);
            }
        },
        BeginStage({ state, commit }, stage) {
            if (state.currentStage != null) {
                if (state.currentStage.id != stage.id) {
                    router.push('/' + state.packageid + '/env/' + stage.id);
                }
            } else {
                router.push('/' + state.packageid + '/env/' + stage.id);
            }
        },
    },
    getters: {
        GetBabPnl: state => id => {
            return state.activeBabylonPanels.find(pnl => pnl.id === id);
        },
        GetInsightTemplateForid: state => templateid => {
            var validTemplates = state.package.insightTemplates.filter(
                template => template.id == templateid,
            );

            if (validTemplates.length > 0) {
                return validTemplates[0];
            } else {
                return null;
            }
        },
        GetInsightStyleForid: state => styleid => {
            var validStyles = state.package.insightStyles.filter(
                style => style.identifier == styleid,
            );

            if (validStyles.length > 0) {
                return validStyles[0];
            } else {
                return null;
            }
        },
        GetAssetForid: state => assetListingid => {
            console.log('Getting asset for: ');
            console.log(assetListingid);
            var validAssets = state.package.assets.filter(
                asset =>
                    asset.assetListingid == assetListingid ||
                    asset.assetDetailid == assetListingid,
            );

            if (validAssets.length > 0) {
                return validAssets[0];
            } else {
                return null;
            }
        },
        CurrentStageData: state => {
            if (state.currentStage == null) return null;
            else return state.currentStage.typeData;
        },
        GetIcons: state => {
            return state.package.icons;
        },
    },
};
