<template>
  <v-layout fill-height="" style="background-color: coral;">
    <v-card color="white" flat width="100%">
      <vue-three-sixty
          style="height:700px;width:100%;max-height:100%"
          :amount=resource.additionalUrls.length
          :imagePath="Get360ProductBaseImagePath"
          :fileName="Get360ProductImageName"
          containerHeight="714px"
          :autoplay="true"
      />
    </v-card>
  </v-layout >
</template>

<script>
  export default {
    name: 'ThreeSixtyResourcesView',
    components: {
    },
    props: {

      resource : null

    },
    computed: {

      Get360ProductBaseImagePath()
      {
          var upToLastDirectory = this.resource.url.substring(0,this.resource.url.lastIndexOf("/"));
          return upToLastDirectory;
      },
      Get360ProductImageName()
      {
          var extension = this.resource.name.split('.')[1];
          var name = this.resource.id + "-" + this.resource.name.substring(0,this.resource.name.lastIndexOf("_"));
          name += "_{index}." + extension;
          console.log(name);
          return name;
      }
    },

    mounted () {

    },
    data: () => ({

    }),
    methods: {

    },
    watch: {
      
    }
  }
</script>

<style>

.v360-fullscreen-toggle
{
  visibility: hidden;
}

</style>
