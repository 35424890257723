<template>
    <fragment>
        <v-main>
            <stage-environment
                v-if="gotPackageData"
                ref="stageEnvironment"
            ></stage-environment>
        </v-main>
        <loading-screen />
    </fragment>
</template>

<script>
import axios from 'axios';
import config from '../../../config.json';
import LoadingScreen from '../LoadingScreen';
import StageEnvironment from '../Stages/Types/StageEnvironment.vue';
import { mapMutations } from 'vuex';
import { mapState, mapGetters } from 'vuex';

import { Fragment } from 'vue-fragment';

export default {
    name: 'Environment',

    components: {
        StageEnvironment,
        LoadingScreen,
        Fragment,
    },
    computed: {
        ...mapState('sessionState', ['package']),
        ...mapGetters('projects', ['getEnvironment']),

        environment() {
            const { projectId } = this.$route.params;
            const project = this.getEnvironment(projectId);

            if (!project) {
                return null;
            }

            return project.environment;
        },
    },

    data: () => ({
        gotPackageData: false,
        //
    }),
    mounted: function() {
        if (this.environment != null) {
            console.log(this.environment);

            var url =
                config.platformEndpoints.coreblobstorage +
                'packagesv2/' +
                this.environment +
                '.json';

            console.log('Getting package at url: ' + url);

            axios.get(url).then(response => {
                this.SetPackageData({
                    id: this.environment,
                    data: response.data,
                });
                this.gotPackageData = true;

                if (response.data.stages.length > 0) {
                    this.SetStageData(response.data.stages[0]);
                }
            });
        }
    },
    methods: {
        ...mapMutations('sessionState', ['SetPackageData', 'SetStageData']),
    },
};
</script>
