import Vue from 'vue';
import VueRouter from 'vue-router';
import Environment from '../components/3DStoreViewer/Environment.vue';
import Dashboard from '../components/3DStoreViewer/Dashboard.vue';
// import StagePresenter from '../components/Stages/StagePresenter.vue';

Vue.use(VueRouter);

const routes = [
    // {
    //     path: '/:packageid',
    //     name: 'ViewerXP',
    //     display: 'ViewerXP',
    //     component: StagePresenter,
    //     props: true,
    // },
    // {
    //     path: '/:packageid/env/:stageid',
    //     name: 'Environment',
    //     display: 'Environment',
    //     component: Environment,
    //     props: true,
    // },
    {
        path: '/project/:projectId',
        name: 'Environment',
        display: 'Environment',
        component: Environment,
        props: true,
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },

    /*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/About.vue')
  }
  */
];

const router = new VueRouter({
    mode: 'hash',
    //base: process.env.BASE_URL,
    base: '/',
    routes,
});

export default router;
