import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { colors } from 'vuetify/lib';
import CustomIcon from '../components/Utility/Customicon.vue';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#26A69A',
                secondary: colors.red.lighten4,
                accent: colors.indigo.base,
            },
        },
    },
    icons: {
        values: {
            custom: {
                component: CustomIcon,
            },
        },
    },
});
