<style>
#editor {
    height: 300px;
}
</style>

<style scoped>
.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}
.title {
    flex: 1;
}
.title-field {
    font-size: 1.25rem;
}
</style>

<template>
    <v-dialog v-model="isVisible" width="800" persistent overlay-opacity="0.8">
        <v-card>
            <v-toolbar color="grey darken-4" dark flat>
                <v-toolbar-title class="title">
                    <v-text-field
                        v-if="isEditing"
                        label="Add your title here..."
                        v-model="content.title"
                        hide-details="auto"
                        single-line
                        background-color="transparent"
                        class="title-field"
                    />
                    <template v-else>
                        {{ content.title }}
                    </template>
                </v-toolbar-title>
                <v-btn icon @click="onClose" color="white">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container>
                <v-row>
                    <v-col md="5">
                        <v-img
                            lazy-src="https://picsum.photos/id/11/10/6"
                            height="350"
                            max-width="400"
                            src="https://picsum.photos/id/11/500/300"
                        ></v-img>
                    </v-col>
                    <v-col md="7">
                        <template v-if="isEditing">
                            <vue-editor
                                id="editor"
                                v-model="content.body"
                                :editor-toolbar="customToolbar"
                            ></vue-editor>
                        </template>
                        <template v-else>
                            <div v-html="content.body" />
                        </template>
                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions v-if="isEditing" class="justify-end">
                <v-btn text @click="onDelete" outlined>
                    <v-icon left> mdi-delete </v-icon>Delete
                </v-btn>
                <v-btn color="primary" :disabled="saved" @click="onSave">
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import { VueEditor } from 'vue2-editor';

export default {
    name: 'InsightPopup',
    components: {
        VueEditor,
    },

    props: {
        id: {
            type: [String, null],
            default: '',
        },
        isVisible: Boolean,
        isEditing: Boolean,
    },

    computed: {
        ...mapGetters('markers', ['getAreaMarkerById']),
        markerContent() {
            const { content } = this.getAreaMarkerById(this.id);

            return content;
        },
    },

    mounted() {
        if (this.markerContent) {
            this.content = this.markerContent;
        }
    },

    data: () => ({
        content: {
            title: '',
            body: '',
            image: '',
            video: '',
        },
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
        saved: true,
    }),

    methods: {
        ...mapMutations('markers', [
            'addAreaMarkerContent',
            'deleteAreaMarker',
        ]),

        onTitleChange(value) {
            this.content.title = value;
        },

        handleReset() {
            var self = this;

            Object.keys(this.content).forEach(function(key) {
                self.content[key] = '';
            });
        },

        onClose() {
            // If not saved. Trigger are you sure?
            this.$emit('closed');
        },

        onSave() {
            this.addAreaMarkerContent({
                id: this.id,
                content: this.content,
            });

            this.$emit('closed');
        },

        onDelete() {
            this.deleteAreaMarker(this.id);
            this.handleReset();
            this.$emit('deleted');
        },
    },
    watch: {
        id: function(newVal, oldVal) {
            //this.activeId = this.id || null;
        },
        content: {
            handler(val) {
                this.saved = false;
            },
            deep: true,
        },
    },
};
</script>
