import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import LoadScript from 'vue-plugin-load-script';
import VuePlyr from 'vue-plyr'

import VueThreeSixty from 'vue-360'
import 'vue-360/dist/css/style.css'

Vue.config.productionTip = false

Vue.use(LoadScript);
Vue.use(VueThreeSixty)
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false }
  },
  emit: ['ended']
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

