<style scoped>
.toolbar {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 30px;
    /* align-content: center; */
    justify-content: center;
    z-index: 1;
}
.button--active {
    background-color: #26a69a !important;
}
.button--active .v-icon {
    color: #fff !important;
}
</style>

<template>
    <fragment>
        <navigation-header>
            <template v-slot:navigationLeft>
                <v-btn small text class="pa-6" @click="onDashboardClick">
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Dashboard
                </v-btn>
            </template>

            <v-btn small text class="pa-6" @click="onSettingsClick">
                <v-icon left>
                    mdi-cog
                </v-icon>
                Project Settings
            </v-btn>
        </navigation-header>

        <insight-popup
            v-if="activeAreaId"
            :isVisible="insightVisible"
            :id="activeAreaId"
            :isEditing="toolbarToggle === 1"
            @closed="onInsightModalClose"
            @deleted="onMarkerDelete"
        />

        <project-settings
            v-if="settingsVisible"
            :isVisible="settingsVisible"
            @closed="onSettingsModalClose"
        />

        <div class="toolbar">
            <v-btn-toggle v-model="toolbarToggle" rounded>
                <v-btn small class="pa-6" active-class="button--active">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn small class="pa-6" active-class="button--active">
                    <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>

        <store-viewer-navigation />
    </fragment>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import shortid from 'shortid';
import { Fragment } from 'vue-fragment';

import NavigationHeader from './NavigationHeader';
import InsightPopup from './InsightPopup';
import ProjectSettings from './ProjectSettings';
import router from '../../router';
import StoreViewerNavigation from './StoreViewerNavigation.vue';

export default {
    name: 'StoreViewer',
    components: {
        Fragment,
        NavigationHeader,
        InsightPopup,
        ProjectSettings,
        StoreViewerNavigation,
    },
    props: {
        waypointId: {
            default: '',
            type: String,
        },
    },
    computed: {
        ...mapState('markers', ['areaMarkers']),
        ...mapGetters('sessionState', ['GetBabPnl']),
        ...mapGetters('markers', ['getAreaMarkers']),

        existingAreaMarkers() {
            const { projectId } = this.$route.params;

            if (!projectId) {
                return null;
            }

            return this.getAreaMarkers(projectId, this.waypointId);
        },
    },

    mounted() {
        this.clearAllMarkers();
        this.createClickHandler();
        this.generateMarkers();

        //   const inst = this.GetBabPnl('MainPanel');
        //   const engine = inst.engine;
        // if (BABYLON.VideoRecorder.IsSupported(engine)) {
        //     var recorder = new BABYLON.VideoRecorder(engine);
        //     recorder.startRecording('3dstore', 60);
        // }
    },

    data: () => ({
        activeAreaId: null,
        toolbarToggle: 0,
        insightVisible: false,
        settingsVisible: false,
    }),

    methods: {
        ...mapMutations('markers', ['addAreaMarker']),

        createClickHandler() {
            const { scene } = this.GetBabPnl('MainPanel');
            var camera = scene.activeCamera;

            scene.onPointerDown = () => {
                // Trigger on ctrl + click
                if (this.toolbarToggle === 1) {
                    this.onCreateNewMarker();
                }

                // Cancel any current animations
                scene.stopAnimation(camera);
            };
        },

        onDashboardClick() {
            router.push('/');
        },

        onSettingsClick() {
            this.settingsVisible = !this.settingsVisible;
        },

        onSettingsModalClose() {
            this.settingsVisible = false;
        },

        onCreateNewMarker() {
            const { scene } = this.GetBabPnl('MainPanel');
            const id = shortid.generate();

            const pickResult = scene.pick(
                scene.pointerX,
                scene.pointerY,
                mesh => {
                    return mesh;
                },
            );

            const { x, y, z } = pickResult.pickedPoint;

            this.addAreaMarker({
                id,
                projectId: this.$route.params.projectId,
                waypoint: this.waypointId,
                points: { x, y, z },
                order: 0,
            });

            this.generateMarker(id, { x, y, z });

            this.insightVisible = true;
            this.activeAreaId = id;
        },

        onMarkerClick(id) {
            this.insightVisible = true;
            this.activeAreaId = id;
        },

        onInsightModalClose() {
            this.insightVisible = false;
            this.activeAreaId = null;
            this.toolbarToggle = 0;
        },

        onMarkerDelete() {
            this.deleteMarker(this.activeAreaId);
            this.activeAreaId = null;
            this.toolbarToggle = 0;
        },

        generateMarker(id, { x, y, z }) {
            const { scene } = this.GetBabPnl('MainPanel');

            const pointPosition = new BABYLON.Vector3(x, y, z);

            let sphereMat = new BABYLON.StandardMaterial(
                `sphereMat_${id}`,
                scene,
            );
            sphereMat.alpha = 0;

            let sphere = BABYLON.Mesh.CreateSphere(
                `sphere_${id}`,
                16,
                0.5,
                scene,
            );

            sphere.position = pointPosition;
            sphere.isVisible = true;
            sphere.material = sphereMat;

            let advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                'UI',
            );

            let button = new BABYLON.GUI.Button.CreateSimpleButton(
                `button_${id}`,
                '+',
            );
            button.width = '70px';
            button.height = '70px';
            button.thickness = 6;
            button.background = '#ffffff';
            button.color = '#26A69A';
            button.position = pointPosition;
            button.cornerRadius = 55;
            button.shadowColor = 'rgba(0,0,0,0.3)';
            button.shadowBlur = 20;
            button.fontSize = 30;
            button.fontWeight = 100;

            advancedTexture.addControl(button);

            button.onPointerClickObservable.add(() => this.onMarkerClick(id));
            button.linkWithMesh(sphere);

            this.$options.spheres.push(sphere);
            this.$options.buttons.push(button);
        },

        generateMarkers() {
            if (this.existingAreaMarkers) {
                this.existingAreaMarkers.forEach(({ id, points }) => {
                    this.generateMarker(id, points);
                });
            }
        },

        deleteMarker(id) {
            this.$options.spheres.forEach((item, i) => {
                if (item.id === `sphere_${id}`) {
                    this.$options.spheres[i].dispose();
                }
            });
            this.$options.buttons.forEach((item, i) => {
                if (item.name === `button_${id}`) {
                    this.$options.buttons[i].dispose();
                }
            });
        },

        clearAllMarkers() {
            console.log('Clearing points');

            if (this.$options.spheres == null) {
                this.$options.spheres = [];
                this.$options.buttons = [];
            }

            this.$options.spheres.forEach((_, i) => {
                this.$options.spheres[i].dispose();
                this.$options.buttons[i].dispose();
            });

            this.$options.spheres = [];
            this.$options.buttons = [];
        },

        // x: 11.30293095971689
        // y: 4.666093926870634
        // z: -93.24378873538082
    },
    watch: {
        areaMarkers(newValue, oldValue) {
            //console.log(newValue);
        },
        waypointId: {
            immediate: true,
            handler(newValue, oldValue) {
                if (oldValue != null) {
                    this.clearAllMarkers();
                    this.generateMarkers();
                }
            },
        },
    },
};
</script>
