<template>
  <div>
    <v-dialog eager v-model="showDialog" max-width="1380px" :fullscreen="presentFullScreen">
      <v-card height="775px" style="overflow-y: hidden;position:relative;" v-if="showDialog">
        <v-card height="100%" width="100%" style="position:absolute;" flat :color="this.ColorToRGBA(colorStyle.mainColor)"/>
        <v-img v-if="backgroundImage != null" height="100%" width="100%" :src="backgroundImage" style="position:absolute;" />

        <v-layout fill-height>
          <v-flex>
            <landing-view v-if="tabs[activeTab].type == 'LANDING'" :colorStyle="colorStyle" :resources="tabs[activeTab].resources" :asset="asset" v-on:nextTab="activeTab += 1"> </landing-view>
            <three-sixty-resource-view v-else-if="tabs[activeTab].type == '360PRODUCT'" :resource="tabs[activeTab].resources[0]"/>
            <video-resources-view v-else-if="tabs[activeTab].type == 'VIDEO'" :resources="tabs[activeTab].resources" />
            <image-resources-view v-else-if="tabs[activeTab].type == 'IMAGE'" :asset="asset"  :resources="tabs[activeTab].resources" :typeData="tabs[activeTab].typeData" :icons="GetIcons"/>
            <asset-overview-view v-else-if="tabs[activeTab].type == 'ASSETOVERVIEW'" :asset="asset" :typeData="tabs[activeTab].typeData" :icons="GetIcons" />
            <article-content-view v-else-if="tabs[activeTab].type == 'CONTENT_ARTICLE'" :content="tabs[activeTab].content" :typeData="tabs[activeTab].typeData"/>
            <content-overview v-else-if="tabs[activeTab].type == 'OVERVIEW_CONTENT'" :asset="asset" :resources="tabs[activeTab].resources" :icons="GetIcons" :content="tabs[activeTab].content" :typeData="tabs[activeTab].typeData"/>
          </v-flex>
          <v-flex shrink style="width:90px">

            <v-card id="tabbacking" width="90px" height="100%" :style="tabStyle" color="transparent" class="pa-0 ma-0" flat />
            <v-btn block="" height="90px" width="90px" :color="ColorToRGBA(colorStyle.secColor)" dark ref="exitbutton" @click="showDialog = false" style="z-index:1;" >
                <img src="https://activstoredev.blob.core.windows.net/test/WA/close.png" width="14px" />
            </v-btn>
             <v-tabs v-model="activeTab" icons-and-text vertical height="600px" color="transparent"  background-color="transparent">

              

              <v-tab v-for="(tab,index) in tabs" :key="index" :style="'border-bottom: 4px solid ' + ColorToRGBA(colorStyle.secColor,0.1) + ';height:90px!important;'" >
                <img v-if="tab.type == 'IMAGE'" src="https://activstoredev.blob.core.windows.net/test/WA/gallery.png" width="28px" :style="(activeTab == index) ? 'filter: brightness(1000%)' : 'filter: brightness(0%)'" />
                <img v-if="tab.type == 'VIDEO'" src="https://activstoredev.blob.core.windows.net/test/WA/video.png" width="33px" :style="(activeTab == index) ? 'filter: brightness(1000%)' : 'filter: brightness(0%)'" />
                <img v-if="tab.type == '360PRODUCT'" src="https://activstoredev.blob.core.windows.net/test/WA/360.png" width="33px" :style="(activeTab == index) ? 'filter: brightness(1000%)' : 'filter: brightness(0%)'" />
                <img v-if="tab.type == 'LANDING'" src="https://activstoredev.blob.core.windows.net/test/WA/Home%20Icon.png" width="28px"  :style="(activeTab == index) ? 'filter: brightness(1000%)' : 'filter: brightness(0%)'" />
                <img v-if="tab.type == 'ASSETOVERVIEW'" src="https://activstoredev.blob.core.windows.net/test/WA/info.png" width="28px"  :style="(activeTab == index) ? 'filter: brightness(1000%)' : 'filter: brightness(0%)'" />
                <img v-if="tab.type == 'CONTENT_ARTICLE'" src="https://activstoredev.blob.core.windows.net/test/WA/info.png" width="28px"  :style="(activeTab == index) ? 'filter: brightness(1000%)' : 'filter: brightness(0%)'" />
                <img v-if="tab.type == 'OVERVIEW_CONTENT'" src="https://activstoredev.blob.core.windows.net/test/WA/info.png" width="28px"  :style="(activeTab == index) ? 'filter: brightness(1000%)' : 'filter: brightness(0%)'" />
              </v-tab>
             </v-tabs>
          </v-flex>
        </v-layout>

       
         

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LandingView from '../Insights/LandingView.vue'
import ThreeSixtyResourceView from '../Insights/ResourceViews/ThreeSixtyResourcesView.vue'
import VideoResourcesView from '../Insights/ResourceViews/VideoResourcesView.vue'
import ImageResourcesView from '../Insights/ResourceViews/ImageResourcesView.vue'
import AssetOverviewView from '../Insights/AssetOverviewView.vue'
import ArticleContentView from '../Insights/ContentView/ArticleContentView.vue'
import ContentOverview from '../Insights/ContentView/ContentOverview.vue'
import { mapGetters } from 'vuex'

  export default {
    name: 'InsightPopupPanel',
    components: {
      ThreeSixtyResourceView,
      VideoResourcesView,
      ImageResourcesView,
      AssetOverviewView,
      ArticleContentView,
      LandingView,
      ContentOverview
    },
    props: {

      touchpoint : null

    },
    computed: {

      tabStyle()
      {
        return 'position:absolute;background-image: linear-gradient(' + this.ColorToRGBA(this.colorStyle.highlightColor) + ',' + this.ColorToRGBA(this.colorStyle.highlightColor,0) + ');'
      },

      backgroundImage()
      {
        if(this.colorStyle != null)
        {
          return this.colorStyle.backgroundurl;
        }
      },
      getBackgroundImageForActiveTab()
      {
        if (this.tabs[this.activeTab].typeData.backgroundUrl != null)
          return this.tabs[this.activeTab].typeData.backgroundUrl;
        else
          return null;
      },
      presentFullScreen () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return false
          case 'lg': return false
          case 'xl': return false
        }
      },
      ...mapGetters("sessionState", [
        "GetAssetForid",
        "GetInsightTemplateForid",
        "GetIcons",
        "GetInsightStyleForid"
      ]),

    },

    mounted () {

      console.log("Mounted");
      this.isMounted = true;

    },
    data: () => ({

      showDialog : false,
      isMounted : false,
      tabs : [],
      asset : null,
      activeTab : 0,
      colorStyle : null
    }),
    methods: {

      ColorToRGBA(styleColor, alphaMultiplier = 1)
      {
        return "rgba(" + styleColor.r * 255 + "," + styleColor.g * 255 + "," + styleColor.b * 225 + "," + (styleColor.a * 255) * alphaMultiplier + ")";
      },
      GetResourceEnumValueforType(enumVal)
      {
        if(enumVal == "IMAGE")
        {
          return 0;
        }
        else if (enumVal == "360PRODUCT")
        {
          return 7;
        }
        else if (enumVal == "VIDEO")
        {
          return 4;
        }
        else{
          return -1;
        }
      },
      GetContentEnumValueforType(enumVal)
      {
        if(enumVal == "CONTENT_ARTICLE" || enumVal == "OVERVIEW_CONTENT")
        {
          return 0;
        }
        else{
          return -1;
        }
      },
      CreateTabsForTouchpoint()
      {
        console.log("Creating tabs for touchpoint");
        this.ClearTabs();
        this.asset = this.GetAssetForid(this.touchpoint.typeData.assetDetailid);
        this.colorStyle = this.GetInsightStyleForid(this.touchpoint.typeData.colorStyle);
        
        console.log("Loaded Asset");
        var insightTemplate = this.GetInsightTemplateForid(this.touchpoint.typeData.templateid);

        for (let t = 0; t < insightTemplate.tabs.length; t++) {
          let tabTemplate = insightTemplate.tabs[t];

          if(this.GetResourceEnumValueforType(tabTemplate.type) != -1)
          {
            let validResources = this.asset.resources.filter(resource => resource.type == this.GetResourceEnumValueforType(tabTemplate.type));
        
            this.tabs.push({
              type : tabTemplate.type,
              resources : validResources,
              typeData : tabTemplate.typeData
            })
          }
          else if (this.GetContentEnumValueforType(tabTemplate.type) != -1)
          {
            let validContent = this.asset.contents.filter(content => content.type == this.GetContentEnumValueforType(tabTemplate.type))

            for (let c = 0; c < validContent.length; c++) {
              let element = validContent[c];
              this.tabs.push({
                type : tabTemplate.type,
                content : element,
                typeData : tabTemplate.typeData
              })
            }
          }
          else{
            this.tabs.push({
              type : tabTemplate.type,
              typeData : tabTemplate.typeData
            })
          }
        }
      },
      ClearTabs()
      {
        this.tabs = [];
        this.asset = null;
        this.activeTab = 0;
      }

    },
    watch: {

      touchpoint : {

        immediate : true,
        handler(newTouchpoint)
        {
          if(newTouchpoint == null)
          {
            this.showDialog = false;
          }
          else
          {
            this.showDialog = true;
            this.CreateTabsForTouchpoint();
          }
          
        }
      },
      showDialog : {

      handler(isShowingDialog)
      {
        if(isShowingDialog)
        {
          this.$nextTick(() => {
            var selected = document.querySelector(".v-tabs-bar__content");
            console.log(selected.style)
            console.log(selected.style.background);
            selected.style.background = "-moz-linear-gradient(rgba(0,0,0,255), rgba(0,0,0,0));"
          })
          
        }
        else
        {
          this.$emit("onCloseInsight")
        }
      }

    }
      
    },
    
  }
</script>

<style>

@font-face {
  font-family: waRegular;
  src: url(https://activstoredev.blob.core.windows.net/test/WA/New%20DirectoryFonts/WorldAthletics-Regular.woff);
}

@font-face {
  font-family: waBold;
  src: url(hhttps://activstoredev.blob.core.windows.net/test/WA/New%20DirectoryFonts/WorldAthletics-Bold.woff);
}

@font-face {
  font-family: waHeadline;
  src: url(https://activstoredev.blob.core.windows.net/test/WA/New%20DirectoryFonts/WorldAthleticsHeadline.woff);
}



</style>
