<style scoped>
.navigation-header {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
}
.navigation-header__sheet {
    overflow: hidden;
}
</style>

<template>
    <div
        class="navigation-header d-flex"
        :class="hasLeftSlot ? 'justify-space-between' : 'justify-end'"
    >
        <div>
            <v-sheet
                v-if="hasLeftSlot"
                color="white"
                elevation="1"
                rounded="xl"
                class="navigation-header__sheet"
            >
                <v-item-group>
                    <slot name="navigationLeft"></slot>
                </v-item-group>
            </v-sheet>
        </div>
        <div>
            <v-sheet
                color="white"
                elevation="1"
                rounded="xl"
                class="navigation-header__sheet"
            >
                <v-item-group>
                    <slot></slot>
                    <v-btn small text class="pa-6">
                        <v-icon left>
                            mdi-account-circle
                        </v-icon>
                        Joe Blogs
                    </v-btn>
                </v-item-group>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavigationHeader',
    components: {},
    props: {},
    computed: {
        hasLeftSlot() {
            return !!this.$slots.navigationLeft;
        },
    },

    mounted() {},
    data: () => ({}),
    methods: {},
    watch: {},
};
</script>
