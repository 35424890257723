import router from '../router/index';
import orderBy from 'lodash/orderBy';
import { v4 as uuidv4 } from 'uuid';

export const ProjectState = {
    namespaced: true,
    state: {
        projects: [],
    },

    mutations: {
        addProject(state, payload) {
            state.projects.push(payload);
        },
        updateProject(state, { uuid, ...payload }) {
            const arr = state.projects.map(obj => {
                if (obj.uuid !== uuid) {
                    return obj;
                }
                return {
                    ...obj,
                    ...payload,
                };
            });

            state.projects = arr;
        },
        deleteProject(state, uuid) {
            const arr = state.projects.filter(obj => obj.uuid !== uuid);
            state.projects = arr;
        },
    },
    actions: {
        addProject: ({ commit }, payload) => {
            const uuid = uuidv4();

            commit('addProject', {
                uuid,
                userId: 1,
                public: false,
                password: null,
                createdAt: Date.now(),
                lastUpdated: Date.now(),
                // title
                // enviroment
                // template
                ...payload,
            });

            router.push(`/project/${uuid}`);
        },
        updateProject: ({ commit }, payload) => {
            commit('updateProject', {
                ...payload,
                lastUpdated: Date.now(),
            });
        },
    },
    getters: {
        getOrderedProjects: state => {
            return orderBy(state.projects, ['lastUpdated'], ['desc']);
        },
        getProjectById: state => uuid => {
            return state.projects.find(obj => obj.uuid === uuid);
        },
        getEnvironment: state => uuid => {
            return state.projects.find(obj => obj.uuid === uuid);
        },
    },
};
