import _merge from 'lodash/merge';
import _keyBy from 'lodash/keyBy';
import _values from 'lodash/values';
import _orderBy from 'lodash/orderBy';

export const MarkerState = {
    namespaced: true,
    state: {
        areaMarkers: [],
    },

    mutations: {
        addAreaMarker(state, payload) {
            state.areaMarkers.push(payload);
        },
        addAreaMarkerContent(state, payload) {
            const { id, content } = payload;

            state.areaMarkers = state.areaMarkers.map(marker => {
                if (id !== marker.id) return marker;
                return { ...marker, content };
            });
        },
        deleteAreaMarker(state, id) {
            const arr = state.areaMarkers.filter(obj => obj.id !== id);
            state.areaMarkers = arr;
        },
        deleteAllProjectMarkers(state, projectId) {
            const arr = state.areaMarkers.filter(
                obj => obj.projectId !== projectId,
            );
            console.log(arr);
            state.areaMarkers = arr;
        },
        updateMultipleMarkers(state, payload) {
            const merged = _merge(
                _keyBy(state.areaMarkers, 'id'),
                _keyBy(payload, 'id'),
            );
            const values = _values(merged);

            console.log(values);

            state.areaMarkers = values;
        },
    },
    actions: {},
    getters: {
        getAreaMarkerById: state => id => {
            return state.areaMarkers.find(obj => obj.id === id);
        },
        getAreaMarkersByWaypointId: state => id => {
            return state.areaMarkers.filter(obj => obj.waypoint === id);
        },
        getAreaMarkers: state => (projectId, waypoint) => {
            return state.areaMarkers.filter(
                obj => obj.projectId === projectId && obj.waypoint === waypoint,
            );
        },
        getAllMarkersByProject: state => projectId => {
            const arr = state.areaMarkers.filter(
                obj => obj.projectId === projectId,
            );

            return _orderBy(arr, ['order'], ['asc']);
        },
    },
};
