<template>
  <v-card width="100%" class="pa-4" color="transparent">
    <h2 style="color:white">
      {{content.title}}
    </h2>
    <span v-html="content.html" style="color:white">
    </span>
  </v-card>
</template>

<script>
  export default {
    name: 'ArticleContentView',
    components: {
    },
    props: {

      typeData : null,
      content : null

    },
    computed: {
    },

    mounted () {

    },
    data: () => ({

    }),
    methods: {



    },
    watch: {
      
    }
  }
</script>
