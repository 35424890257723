import router from '../router/index';
import orderBy from 'lodash/orderBy';
import { v4 as uuidv4 } from 'uuid';

export const TemplateState = {
    namespaced: true,
    state: {
        templates: [
            // uuid
            // title
        ],
    },

    mutations: {
        addTemplate(state, payload) {
            //state.templates.push(payload);
            //console.log(payload);
        },
        // updateTemplate(state, { uuid, ...payload }) {
        //     const arr = state.projects.map(obj => {
        //         if (obj.uuid !== uuid) {
        //             return obj;
        //         }
        //         return {
        //             ...obj,
        //             ...payload,
        //         };
        //     });

        //     state.projects = arr;
        // },
        // deleteTemplate(state, uuid) {
        //     const arr = state.projects.filter(obj => obj.uuid !== uuid);
        //     state.projects = arr;
        // },
    },
    actions: {
        addTemplate: ({ commit, dispatch }, payload) => {
            const uuid = uuidv4();
            const template = { uuid, ...payload };

            commit('addTemplate', template);

            dispatch('templateMarkers/addAreaMarkers', template, {
                root: true,
            });
        },
    },
    getters: {
        // TODO: Get templates by enviroment id

        getOrderedTemplates: state => enviromentId => {
            return orderBy(state.templates, ['title'], ['asc']);
        },
    },
};
