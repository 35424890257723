<template>
<div>
  <v-card style="position:absolute;" width="100%" height="100%" flat color="transparent">
      <v-card :style="gradientStyle" width="100%" height="100%">
        <v-img 
        src="https://activstoredev.blob.core.windows.net/test/WA/WAStudioBackground.png"
        />
      </v-card>
  </v-card>
  <v-row no-gutters  align="center" justify="center" style="position:relative">

    <v-col cols="7" class="pr-4">
      <v-img  height="775px" style="max-width:600px" contain :src="landingImage">
        
      </v-img>
    </v-col>
    <v-col cols="4" class="pl-0">
        <v-card class="mb-4" width="220px" height="140px" style="position:relative" flat color="transparent" v-if="headerImage != null">
            <v-img
              :src="headerImage"
              height="140px"
              contain
              style="position:absolute;"
              />
        </v-card>



        <h1 style="color:white;font-family:waHeadline;font-size:42px;line-height: normal;" class="mt-0 pt-0 pb-4">
          <v-card v-if="avatarImage != null" width="125px" height="125px" style="position:absolute;transform: translate(-150px, 0);" color="transparent" flat>
            <v-img :src="avatarImage">

            </v-img>
          </v-card>
          {{asset.name}}
        </h1>
  
        <div v-if="asset.description != null && asset.description.length > 0" style="border-top: 2px solid white;" class="pt-4">
          <p style="color:white;font-family:waRegular;font-size:42px;line-height: normal;" class="mt-0 pt-0">
            <span >
            {{asset.description}}
            </span>
          </p>
        </div>

        <v-card v-else color="transparent" flat hover @click="RequestNextSlide">
          <p style="color:white;font-family:waRegular;font-size:25px" class="mt-0 pt-0">
            Click here for more...
          </p>
        </v-card>

        <div v-if="keyFactContent != null" class="pt-4">
          <div v-for="(fact,index) in keyFactContent.facts" :key="index" class="mb-4">
            <p class="pb-0 mb-0" style="color:white;font-family:waRegular;font-size:18px">
              <i>{{fact.key}}</i>
            </p>
            <span  style="color:white;font-family:waRegular;font-size:42px;text-decoration: underline;">
              {{fact.value}}
            </span>
          </div>
        </div>
     
    </v-col>
    <v-col cols="1">

    </v-col>
  </v-row>
</div>
</template>

<script>
  export default {
    name: 'LandingView',
    components: {
    },
    props: {
      resources : Array,
      asset : {},
      colorStyle : null
    },
    computed: {

      keyFactContent()
      {
        var contentWithKeyFrames = this.asset.contents.filter(content => content.type ==  2);

        if(contentWithKeyFrames.length > 0)
        {
          return contentWithKeyFrames[0];
        }
        else
        {
          return null;
        }
      },
      gradientStyle()
      {
        return 'background-image: linear-gradient(' + this.ColorToRGBA(this.colorStyle.landGradOne) + ',' + this.ColorToRGBA(this.colorStyle.landGradTwo) + ');'
      },

      landingImage ()
      {
        var filteredImages = this.asset.resources.filter(e => e.tags.includes('Landing'));
        
        if(filteredImages.length > 0)
          return filteredImages[0].url
        else
          return null;
      },
      headerImage()
      {
        var filteredImages = this.asset.resources.filter(e => e.tags.includes('logo'));
        
        if(filteredImages.length > 0)
          return filteredImages[0].url
        else
          return null;
      },
      avatarImage()
      {
        var filteredImages = this.asset.resources.filter(e => e.tags.includes('avatar'));

        if(filteredImages.length > 0)
          return filteredImages[0].url
        else
          return null;
      }


    },

    mounted () {

    },
    data: () => ({

    }),
    methods: {

      ColorToRGBA(styleColor, alphaMultiplier = 1)
      {
        return "rgba(" + styleColor.r * 255 + "," + styleColor.g * 255 + "," + styleColor.b * 225 + "," + (styleColor.a * 255) * alphaMultiplier + ")";
      },
      RequestNextSlide()
      {
        this.$emit("nextTab");
      }

    },
    watch: {
      
    }
  }
</script>

<style scoped>

.gradient
{
  background-image:  linear-gradient(rgba(15,106,176,255), rgba(8,64,123,255));
}


</style>
