<template>
<v-layout fill-height="" column="" style="position:relative;background-color:#1e1e1e;height:775px">
  <v-card v-if="isGalleryView" height="775px" style="overflow-y: auto;" flat color="#1e1e1e">
      <v-container fluid class="height:100px pt-0 pb-0">
      <v-row>
        <v-col cols="12">
          <h1 style="color:white;">
            {{asset.name}}
          </h1>
        </v-col>
        <v-col
        v-for="(resource,i) in filteredResources" :key="i"
        class="d-flex child-flex pa-1"
        cols="2"
        >
        <v-card flat tile hover="" @click="OnClickGalleryImage(i)" color="transparent">
          <v-img
            :src="resource.url"
            aspect-ratio="1"
            class="grey lighten-2"
          >
          </v-img>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-carousel
   v-else
   v-model="activeResource"
    
    height="770px"
    show-arrows-on-hover
    hide-delimiters=""
  >
  
    <v-carousel-item
      v-for="(resource, i) in filteredResources"
      :key="i"
    >
    <v-layout class="pa-4" height="570px" align-center justify-center>
      <v-flex shrink>
        <div style="max-width:660px">
          <img style="object-fit: contain;" height="640px" width="100%"  :src="resource.url">
        </div>
      </v-flex>
      <v-flex shrink class="pa-2 ml-4">
         <v-card class width="240px" height="200px" color="transparent">
          <div v-if="typeData.useIcons" style="">
            <v-img :src="'https://activstoredev.blob.core.windows.net/test/WA/Flags/ExampleFlag.png'" height="80px" width="40px" contain/>
          </div>
          <div style="border-top: 2px solid white;width:100%;height:2px" class="mb-4"  />
          <span class="title pr-1" style="font-family:waHeadline !important;" >{{NameWithoutExtension(resource.name)}}</span>
          <div v-if="resource.description.length > 0" style="border-top: 2px solid #FFFFFF;width:50%;height:2px" class="mt-4 mb-4"  />
          <span class="subtitle" style="font-family:waRegular">{{resource.description}}</span>
          <div style="border-bottom: 2px solid white;width:100%;height:2px" class="mt-4"  />
        </v-card>
      </v-flex>
    </v-layout>
      
     
    </v-carousel-item>
  </v-carousel>
  <v-card v-if="!isGalleryView" ref="imageSelector" style="z-index:10;position:absolute;right:0%;left:0%;bottom:0%;" class="mt-2" height="76px" color="transparent" flat>
    <v-slide-group
      v-model="activeResource"
      center-active
      show-arrows
      mandatory
      dark
    >
      <v-slide-item
        v-for="(resource, i) in filteredResources"
        :key="i"
        v-slot:default="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : 'grey lighten-1'"
          height="76"
          width="76"
          class="mr-1"
          @click="toggle"
          style="border-radius:0px"
        >
          <v-img
          height="100%"
          :src="resource.url"
          >

          </v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-card> 

  <v-btn v-if="allowGalleryView && !isGalleryView" @click="isGalleryView=true" dark floating right top fab style="position:absolute;" small="" class="ma-2" color="rgba(0,0,0,0.6)">
    <v-icon>
      mdi-table-large
    </v-icon>
  </v-btn>
  <!--
  <v-card flat="" color="rgba(0,0,0,0.45)" v-if="hasDescription && !isGalleryView" class="pa-4" style="position:absolute;bottom:0;right:0;left:0">
    <span style="color:white">{{resources[activeResource].description}}</span>
  </v-card>
  -->
</v-layout>
 
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
  export default {
    name: 'ImageResourcesView',
    components: {
    },
    props: {

      asset : {},
      resources : Array,
      typeData : null,
      icons : Array
    },
    computed: {

      filteredResources()
      {
        var filteredResources = this.resources.filter(e => !e.tags.includes('logo') && !e.tags.includes('NoGallery'));
        return filteredResources;
      },
      hasDescription()
      {
        return (this.resources[this.activeResource].description != null && this.resources[this.activeResource].description.length > 0)
      }

    },

    mounted () {


    },
    data: () => ({

      isGalleryView : false,
      allowGalleryView : false,
      activeResource : 0,
      cursorInsideGallery : false

    }),
    methods: {

      GetIconForResource(resource)
      {
        for (let index = 0; resource.tags.length; index++) {
          
          var matchingIcon = this.icons.filter(e => e.tag == resource.tags[index]);

          if(matchingIcon.length > 0)
          {
            return matchingIcon[0].url;
          }
        }

        return null;
      },
      NameWithoutExtension(name)
      {
        var splitString = name.split('.');
        return splitString[0];
      },
      OnClickGalleryImage(index)
      {
        this.activeResource = index;
        this.isGalleryView = false;
      }
    },
    watch: {

      
      typeData : {

        immediate : true,
        handler(newVal)
        {
          if(newVal != null)
          {
            this.isGalleryView = newVal.showGallery;
            this.allowGalleryView = newVal.showGallery;
          }
        }
      },
      cursorInsideGallery : {

        handler(isInsideGallery)
        {
          console.log("Mouse change");
          console.log(isInsideGallery);
          if(isInsideGallery)
          {
            anime({
              targets: this.$refs.imageSelector.$el,
              opacity: 0.9,
              duration: 300,
              easing: 'linear'
            });
          }
          else
          {
            anime({
              targets: this.$refs.imageSelector.$el,
              opacity: 0.2,
              duration: 300,
              easing: 'linear'
            });
          }
        }
      }
      
    }
  }
</script>
