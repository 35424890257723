<template>
    <div
        style="position:fixed;z-index:100;height:100vh;width:100vw;pointer-events: none;"
    >
        <v-card
            style="position:absolute;"
            ref="mainLoadScreen"
            flat=""
            color="black"
            height="100vh"
            width="100vw"
        >
            <v-img
                :src="loadingImage"
                height="100vh"
                width="100vw"
                style="position:absolute"
            >
            </v-img>
            <v-row align="center" justify="center" style="height:100vh">
                <v-col cols="auto">
                    <!-- <v-card color="transparent" class="pa-2 mb-4" flat>
                        <v-img height="200px" width="200px" contain="" src="">
                        </v-img>
                    </v-card> -->
                    <v-card
                        color="rgba(0,0,0,0.4)"
                        class="pa-2 font-weight-bold"
                        style="color:white;"
                    >
                        <v-row no-gutters="" align="center" justify="center">
                            <v-col cols="auto">
                                <v-progress-circular
                                    :size="30"
                                    class="mr-2"
                                    color="white"
                                    indeterminate
                                ></v-progress-circular>
                                <span>
                                    Loading Environment
                                </span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <!-- <v-card
            style="position:absolute;opacity:0;"
            ref="fadeoutScreen"
            flat=""
            color="white"
            height="100vh"
            width="100vw"
        >
            <v-row
                no-gutters
                align="center"
                justify="center"
                style="height:100vh"
            >
                <v-col cols="auto" style="opacity:0.2">
                    <v-card class="mt-4 text-center font-weight-bold" flat>
                       
                    </v-card>
                </v-col>
            </v-row>
        </v-card> -->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import anime from 'animejs/lib/anime.es.js';

export default {
    name: 'LoadingScreen',
    components: {},
    props: {},
    computed: {
        ...mapState('sessionState', ['showMainLoadScreen', 'showFadeout']),

        loadingImage() {
            return 'https://3dstoreviewer.s3.eu-west-2.amazonaws.com/backgroundBlur711.jpg';
        },
    },

    mounted: () => {},
    data: () => ({}),
    methods: {},
    watch: {
        showMainLoadScreen: {
            handler(isVisible) {
                if (isVisible) {
                    anime({
                        targets: this.$refs.mainLoadScreen.$el,
                        opacity: 1,
                        duration: 150,
                        easing: 'linear',
                    });
                } else {
                    anime({
                        targets: this.$refs.mainLoadScreen.$el,
                        opacity: 0,
                        duration: 150,
                        easing: 'linear',
                    });
                }
            },
        },
        showFadeout: {
            handler(isVisible) {
                console.log('Fadeout');
                console.log(isVisible);
                if (isVisible) {
                    console.log('Showing fadeout');
                    anime({
                        targets: this.$refs.fadeoutScreen.$el,
                        opacity: 1,
                        duration: 300,
                        easing: 'linear',
                    });
                } else {
                    console.log('Hiding fadeout');
                    anime({
                        targets: this.$refs.fadeoutScreen.$el,
                        opacity: 0,
                        duration: 300,
                        easing: 'linear',
                    });
                }
            },
        },
    },
};
</script>
