import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import * as sessionState from './SessionState';
import * as trackedState from './TrackedState';
import * as markersState from './MarkerState';
import * as projectState from './ProjectState';
import * as templateState from './TemplateState';
import * as templateMarkerState from './TemplateMarkerState';
import * as userState from './UserState';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: 'storeviewer',
    storage: window.localStorage,
    supportCircular: true,
    reducer: state => ({
        projects: state.projects,
        markers: state.markers,
        templates: state.templates,
        templateMarkers: state.templateMarkers,
        user: state.user,
    }),
});

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        sessionState: sessionState.SessionState,
        trackedState: trackedState.TrackedState,
        markers: markersState.MarkerState,
        projects: projectState.ProjectState,
        templates: templateState.TemplateState,
        templateMarkers: templateMarkerState.TemplateMarkerState,
        user: userState.UserState,
    },
    plugins: [vuexLocal.plugin],
});
