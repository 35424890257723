<template>
    <div></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
    name: 'SphereNavigator',
    components: {},
    props: {
        isActive: false,
        babylonPanelIdentifier: '',
        waypoint: null,
        waypoints: Array,
        waypointNavigation: false,
        navigationType: '',
    },
    computed: {
        ...mapGetters('sessionState', ['GetBabPnl', 'GetCurrentStageData']),
        ...mapState('sessionState', ['jumpToWaypointid']),
    },

    mounted: () => {},
    data: () => ({
        processedWaypoints: null,
        isInitalized: false,
        currentWaypoint: null,
        firstWaypointLoad: true,
        waypointVisibilityDistance: 100,
        isLoading: false,
    }),
    methods: {
        Init() {
            console.log('Initalizing Sphere Navigator');
            this.CreateCamera();
            this.CreateSphere();
            this.ProcessWaypoints();
            console.log('Sphere Navigator Initalized');
        },
        CreateCamera() {
            console.log('Creating camera');

            var inst = this.GetBabPnl(this.babylonPanelIdentifier);
            var camera;
            camera = new BABYLON.FreeCamera(
                'UniversalCamera',
                new BABYLON.Vector3(0, 0, 0),
                inst.scene,
            );
            camera.fov = 1;
            camera.lowerRadiusLimit = 0.001;
            camera.upperRadiusLimit = 0.001;
            camera.allowUpsideDown = false;
            camera.minZ = 0.01;
            camera.inputs.attached.mouse.angularSensibility =
                camera.inputs.attached.mouse.angularSensibility * -2;
            camera.interia = 0.2;
            camera.inputs.attached.keyboard.detachControl();
            var inputManager = camera.inputs;
            camera.attachControl(inst.canvas, true);
            this.$options.camera = camera;
            camera.inputs.attached.keyboard.detachControl();
            this.$options.camera = camera;
            this.$options.mainUi = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI(
                'UI',
                true,
            );

            console.log(camera.inputs.attached.mouse);

            inst.scene.registerBeforeRender(() => {
                if (!this.isLoading && this.currentWaypoint != null) {
                    if (camera.rotation.x < -0.6) {
                        camera.rotation.x = -0.6;
                    }
                    if (camera.rotation.x > 0.6) {
                        camera.rotation.x = 0.6;
                    }

                    if (this.currentWaypoint.locked == true) {
                        let anchorRadians = BABYLON.Tools.ToRadians(
                            this.currentWaypoint.euler.y,
                        );
                        let angleDistance =
                            this.$options.camera.rotation.y - anchorRadians;
                        if (Math.abs(angleDistance) > 1.5) {
                            if (angleDistance > 0) {
                                camera.rotation.y = anchorRadians + 1.5;
                            } else {
                                camera.rotation.y = anchorRadians - 1.5;
                            }
                        }
                    }
                }
            });
        },
        CreateSphere() {
            var inst = this.GetBabPnl(this.babylonPanelIdentifier);
            console.log('Creating Sphere');
            // The first parameter can be set to null to load all meshes and skeletons
            BABYLON.SceneLoader.ImportMesh(
                '',
                'https://activlego.blob.core.windows.net/babwebgl/Models/',
                'HalfSphereV3.glb',
                inst.scene,
                (meshes, particleSystems, skeletons) => {
                    meshes[0].scaling = new BABYLON.Vector3(-100, -100, 100);
                    this.$options.sphere = meshes[0];
                    this.$options.leftSphere = meshes[2];
                    this.$options.rightSphere = meshes[1];

                    var leftmat = new BABYLON.StandardMaterial(
                        'leftSphereMaterial',
                        inst.scene,
                    );
                    leftmat.backFaceCulling = false;
                    leftmat.disableLighting = true;
                    this.$options.leftSphere.material = leftmat;
                    this.$options.leftSphere.isPickable = false;

                    var rightMat = new BABYLON.StandardMaterial(
                        'rightSphereMaterial',
                        inst.scene,
                    );
                    rightMat.backFaceCulling = false;
                    rightMat.disableLighting = true;
                    this.$options.rightSphere.material = rightMat;
                    this.$options.rightSphere.isPickable = false;

                    this.isInitalized = true;
                    this.$emit('initalized');
                },
            );
        },
        LoadWaypoint() {
            this.isLoading = true;
            this.$emit('LoadingWaypoint', this.currentWaypoint);
            setTimeout(() => {
                if (this.$options.leftSphere.material.emissiveTexture != null) {
                    this.$options.leftSphere.material.emissiveTexture.dispose();
                    this.$options.leftSphere.material.emissiveTexture = null;
                }

                if (
                    this.$options.rightSphere.material.emissiveTexture != null
                ) {
                    this.$options.rightSphere.material.emissiveTexture.dispose();
                    this.$options.rightSphere.material.emissiveTexture = null;
                }

                var inst = this.GetBabPnl(this.babylonPanelIdentifier);

                var assetsManager = new BABYLON.AssetsManager(inst.scene);
                assetsManager.useDefaultLoadingScreen = false;

                var leftTexture = null;
                var rightTexture = null;

                var leftTextureTask = assetsManager.addTextureTask(
                    'LeftSphereTask',
                    this.currentWaypoint.leftImgUrl,
                );
                leftTextureTask.onSuccess = task => {
                    leftTexture = task.texture;
                    leftTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
                };

                var rightTextureTask = assetsManager.addTextureTask(
                    'RightSphereTask',
                    this.currentWaypoint.rightImgUrl,
                );
                rightTextureTask.onSuccess = task => {
                    rightTexture = task.texture;
                    rightTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
                };

                console.log('Loading waypoint');

                assetsManager.load();
                assetsManager.onFinish = tasks => {
                    this.isLoading = false;
                    if (this.firstWaypointLoad) {
                        console.log('Setting rotation to');
                        console.log(-(360 - this.currentWaypoint.euler.x));
                        this.$options.camera.rotation = new BABYLON.Vector3(
                            BABYLON.Tools.ToRadians(
                                this.currentWaypoint.euler.x,
                            ),
                            BABYLON.Tools.ToRadians(
                                this.currentWaypoint.euler.y,
                            ),
                            0,
                        );
                        this.firstWaypointLoad = false;
                        this.currentWaypoint.isFirst = true;
                    }

                    this.$options.leftSphere.material.emissiveTexture = leftTexture;
                    this.$options.rightSphere.material.emissiveTexture = rightTexture;
                    this.UpdateCameraForWaypoint(this.currentWaypoint);

                    this.UpdateFloorMarkers();
                    this.$emit('WaypointLoaded', this.currentWaypoint);
                };
            }, 150);
        },
        UpdateCameraForWaypoint(newWaypoint) {
            this.$options.camera.position = new BABYLON.Vector3(
                newWaypoint.pos.x,
                newWaypoint.pos.y,
                newWaypoint.pos.z,
            );
            this.$options.sphere.position = new BABYLON.Vector3(
                newWaypoint.pos.x,
                newWaypoint.pos.y,
                newWaypoint.pos.z,
            );

            if (newWaypoint.locked == true) {
                let anchorRadians = BABYLON.Tools.ToRadians(
                    newWaypoint.euler.y,
                );
                let angleDistance =
                    this.$options.camera.rotation.y - anchorRadians;
                if (Math.abs(angleDistance) > 1.5) {
                    this.$options.camera.rotation = new BABYLON.Vector3(
                        BABYLON.Tools.ToRadians(newWaypoint.euler.x),
                        BABYLON.Tools.ToRadians(newWaypoint.euler.y),
                        0,
                    );
                }
            }
        },
        UpdateFloorMarkers() {
            if (this.$options.floorMarkers != null) {
                for (let i = 0; i < this.$options.floorMarkers.length; i++) {
                    this.$options.floorMarkers[i].dispose();
                }
            }
            this.$options.floorMarkers = [];

            var inst = this.GetBabPnl(this.babylonPanelIdentifier);

            let matchingWaypoints = this.processedWaypoints.filter(e =>
                this.currentWaypoint.connectedWaypoints.includes(e.id),
            );

            for (let i = 0; i < matchingWaypoints.length; i++) {
                const waypoint = matchingWaypoints[i];

                var box = BABYLON.MeshBuilder.CreatePlane(
                    'Clickable',
                    { width: 1.707 * 0.025, height: 0.993 * 0.025 },
                    inst.scene,
                );
                let dirVec = new BABYLON.Vector3(
                    waypoint.pos.x - this.currentWaypoint.pos.x,
                    0,
                    waypoint.pos.z - this.currentWaypoint.pos.z,
                ).normalize();
                let angle = BABYLON.Vector3.GetAngleBetweenVectors(
                    new BABYLON.Vector3(0, 0, 1),
                    dirVec,
                    new BABYLON.Vector3(0, 1, 0),
                );

                box.rotation = new BABYLON.Vector3(1.57079633, angle, 0);
                var matBox = new BABYLON.StandardMaterial('matBox', inst.scene);
                matBox.diffuseTexture = new BABYLON.Texture(
                    'https://3dstoreviewer.s3.eu-west-2.amazonaws.com/waypointArrow.png',
                    inst.scene,
                );
                matBox.diffuseTexture.hasAlpha = true;
                matBox.useAlphaFromDiffuseTexture = true;
                matBox.emissiveColor = BABYLON.Color3.White();
                matBox.diffuseColor = new BABYLON.Color3(1.0, 0.1, 0.1);
                box.material = matBox;
                box.isPickable = true;
                box.position = new BABYLON.Vector3(
                    this.currentWaypoint.pos.x,
                    this.currentWaypoint.pos.y - 0.1,
                    this.currentWaypoint.pos.z,
                );

                box.translate(dirVec, 0.2, BABYLON.Space.WORLD);

                this.$options.floorMarkers.push(box);
                box.actionManager = new BABYLON.ActionManager(inst.scene);
                box.actionManager.registerAction(
                    new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPickTrigger,
                        () => {
                            this.OnClickFloorMarker(waypoint);
                        },
                    ),
                );

                box.actionManager.registerAction(
                    new BABYLON.SetValueAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        box.material,
                        'emissiveColor',
                        BABYLON.Color3.White(),
                    ),
                );
                box.actionManager.registerAction(
                    new BABYLON.SetValueAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        box.material,
                        'emissiveColor',
                        new BABYLON.Color3(0, 1, 1),
                    ),
                );
                box.actionManager.registerAction(
                    new BABYLON.InterpolateValueAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        box,
                        'scaling',
                        new BABYLON.Vector3(1, 1, 1),
                        150,
                    ),
                );
                box.actionManager.registerAction(
                    new BABYLON.InterpolateValueAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        box,
                        'scaling',
                        new BABYLON.Vector3(1.15, 1.15, 1.15),
                        150,
                    ),
                );

                // console.log("Created box at");
                // console.log(box.position);
            }
        },
        OnClickFloorMarker(waypoint) {
            this.currentWaypoint = waypoint;

            console.log('Clicked on floor marker');
            console.log(waypoint);
        },
        ProcessWaypoints() {
            this.processedWaypoints = [];

            for (let i = 0; i < this.waypoints.length; i++) {
                const wypn = this.waypoints[i];

                if (wypn.connectedWaypoints != null) {
                    this.processedWaypoints.push(wypn);
                    continue;
                }

                wypn.connectedWaypoints = [];
                let wypnPOS = new BABYLON.Vector3(
                    wypn.pos.x,
                    wypn.pos.y,
                    wypn.pos.z,
                );

                for (let w = 0; w < this.waypoints.length; w++) {
                    const compareWypn = this.waypoints[w];
                    if (compareWypn.id == wypn.id) continue;

                    if (compareWypn.navigatable != null) {
                        if (!compareWypn.navigatable) {
                            continue;
                        }
                    }

                    let compareWypnPOS = new BABYLON.Vector3(
                        compareWypn.pos.x,
                        compareWypn.pos.y,
                        compareWypn.pos.z,
                    );

                    let distance = BABYLON.Vector3.Distance(
                        wypnPOS,
                        compareWypnPOS,
                    );

                    if (distance < this.waypointVisibilityDistance) {
                        wypn.connectedWaypoints.push(compareWypn.id);
                    }
                }

                this.processedWaypoints.push(wypn);
            }

            //this.LoadWaypointMarkers();
        },
        LoadWaypointMarkers() {
            //Clear old markers
            if (this.$options.panels != null) {
                for (
                    let index = 0;
                    index < this.$options.panels.length;
                    index++
                ) {
                    this.$options.panels[index].dispose();
                }
            }
            this.$options.panels = [];

            if (this.$options.dummySpheres != null) {
                for (
                    let index = 0;
                    index < this.$options.dummySpheres.length;
                    index++
                ) {
                    this.$options.dummySpheres[index].dispose();
                }
            }
            this.$options.dummySpheres = [];

            console.log('Loading waypoint markers');
            var inst = this.GetBabPnl(this.babylonPanelIdentifier);

            var sphereMat = new BABYLON.StandardMaterial(
                'sphereMat',
                inst.scene,
            );
            sphereMat.alpha = 0;

            for (let i = 0; i < this.waypoints.length; i++) {
                let sphere = BABYLON.Mesh.CreateSphere(
                    'Sphere_' + this.waypoints[i].id,
                    16,
                    0.5,
                    inst.scene,
                );
                // Move the sphere upward 1/2 its height
                sphere.position = new BABYLON.Vector3(
                    this.waypoints[i].pos.x,
                    this.waypoints[i].pos.y,
                    this.waypoints[i].pos.z,
                );

                sphere.isVisible = true;
                sphere.material = sphereMat;

                this.$options.dummySpheres.push(sphere);

                var wldpanel = new BABYLON.GUI.StackPanel();
                wldpanel.width = '150px';

                if (this.waypoints[i].name.length > 0) {
                    wldpanel.height = '200px';
                } else {
                    wldpanel.height = '50px';
                }
                this.$options.mainUi.addControl(wldpanel);
                wldpanel.linkWithMesh(sphere);
                wldpanel.isVisible = true;

                this.$options.panels.push(wldpanel);

                if (this.waypoints[i].name.length > 0) {
                    var label = new BABYLON.GUI.Rectangle('label');
                    label.background = 'black';
                    label.height = '40px';
                    label.alpha = 0.7;
                    label.width = '150px';
                    label.cornerRadius = 20;
                    label.thickness = 0;
                    label.top = '10%';
                    label.parent = textblock;
                    wldpanel.addControl(label);

                    var textblock = new BABYLON.GUI.TextBlock();
                    textblock.height = '50px';
                    textblock.color = 'white';
                    textblock.fontSize = 16;
                    textblock.text = this.waypoints[i].name;
                    textblock.textWrapping = true;
                    label.addControl(textblock);
                }

                var wldbutton = BABYLON.GUI.Button.CreateImageOnlyButton(
                    'but',
                    'https://activstorecore.blob.core.windows.net/test/hackimages/waypoint.png',
                );
                wldbutton.width = '50px';
                wldbutton.height = '50px';
                wldbutton.cornerRadius = 20;
                wldbutton.thickness = 0;
                wldbutton.color = 'black';

                wldbutton.onPointerUpObservable.add(() => {
                    this.currentWaypoint = this.waypoints[i];
                });

                wldpanel.addControl(wldbutton);
            }
        },
        jumpToWaypoint(waypoint) {
            const newWaypoint = this.processedWaypoints.find(
                obj => obj.id === waypoint,
            );

            if (newWaypoint) {
                this.currentWaypoint = newWaypoint;
            }
        },
    },
    watch: {
        //If set to active and we've not initalized, let's then do so
        isActive: {
            handler(newVal) {
                if (newVal) {
                    this.Init();
                }
            },
        },
        waypoints: {
            handler(newVal) {
                console.log('Waypoints changed, creating new markers');
                this.firstWaypointLoad = true;
                this.ProcessWaypoints();
            },
        },
        waypoint: {
            immediate: true,
            handler(newVal) {
                if (this.isInitalized && newVal != null) {
                    this.currentWaypoint = newVal;
                }
            },
        },
        currentWaypoint: {
            immediate: true,
            handler(newVal) {
                console.log('Changed waypoint');
                if (this.isInitalized && newVal != null) {
                    this.LoadWaypoint();
                }
            },
        },
        jumpToWaypointid: {
            immediate: false,
            handler(newVal) {
                if (newVal) {
                    this.jumpToWaypoint(newVal);
                }
            },
        },
    },
};
</script>
