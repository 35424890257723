<style>
.presentationToolbar {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
}
.presentationToolbar__sheet {
    overflow: hidden;
}
</style>

<template>
    <fragment>
        <div class="presentationToolbar">
            <v-sheet
                color="white"
                elevation="1"
                rounded="xl"
                class="presentationToolbar__sheet"
            >
                <v-item-group>
                    <v-btn small text class="pa-6" @click="goToMarker('prev')">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-btn small text class="pa-6" @click="handleOrderToggle">
                        <v-icon>mdi-sort</v-icon>
                    </v-btn>
                    <v-btn small text class="pa-6" @click="goToMarker('next')">
                        <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                </v-item-group>
            </v-sheet>
        </div>
        <transition-order
            :isVisible="isOrderVisible"
            @closed="handleOrderToggle"
        />
    </fragment>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { Fragment } from 'vue-fragment';

import TransitionOrder from './TransitionOrder.vue';

export default {
    name: 'StoreViewerNavigation',
    components: { TransitionOrder, Fragment },
    props: {},
    computed: {
        ...mapGetters('sessionState', ['GetBabPnl']),
        ...mapGetters('markers', ['getAllMarkersByProject']),
        ...mapState('sessionState', ['activeWaypointid']),
        ...mapState('markers', ['areaMarkers']),

        projectMarkers() {
            const { projectId } = this.$route.params;
            return this.getAllMarkersByProject(projectId);
        },
    },
    mounted() {
        this.updateMarkers();
    },
    data: () => ({
        toolbarToggle: 0,

        // Update below on watch

        markers: [],
        totalMarkers: 0,
        index: -1,
        isOrderVisible: false,
    }),
    methods: {
        ...mapMutations('sessionState', ['SetJumpToWaypoint']),

        updateMarkers() {
            this.markers = this.projectMarkers;
            this.totalMarkers = this.projectMarkers.length;
        },

        getMarker(dir) {
            if (this.markers.length < 1) return null;

            if (dir === 'next' && this.index + 1 < this.totalMarkers) {
                this.index = this.index + 1;
            } else if (dir === 'prev' && this.index > 0) {
                this.index = this.index - 1;
            } else {
                return null;
            }

            const { waypoint, points } = this.markers[this.index];

            if (waypoint !== this.activeWaypointid) {
                this.SetJumpToWaypoint(waypoint);
            }

            return points;
        },

        goToMarker(dir) {
            const frameRate = 10;

            const { scene } = this.GetBabPnl('MainPanel');

            var camera = scene.activeCamera;

            var animationcamera = new BABYLON.Animation(
                'pathAnim',
                'target',
                frameRate,
                BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
                BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
            );

            const currentCameraTarget = camera.target.clone();

            // Check if empty
            const points = this.getMarker(dir);

            if (points) {
                const { x, y, z } = points;
                const newPos = new BABYLON.Vector3(x, y, z);

                const distance = BABYLON.Vector3.Distance(
                    currentCameraTarget,
                    newPos,
                );

                var keys = [];

                keys.push({
                    frame: 0,
                    value: currentCameraTarget,
                });

                keys.push({
                    frame: distance / frameRate,
                    value: newPos,
                });

                animationcamera.setKeys(keys);
                camera.animations = [];
                camera.animations.push(animationcamera);

                scene.beginAnimation(camera, 0, distance / frameRate, false);
            }
        },
        handleOrderToggle() {
            this.isOrderVisible = !this.isOrderVisible;
        },
    },
    watch: {
        areaMarkers() {
            this.updateMarkers();
        },
    },
};
</script>
