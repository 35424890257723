<style>
#editor {
    height: 300px;
}
</style>

<style scoped>
.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>

<template>
    <fragment>
        <v-dialog
            v-model="isVisible"
            width="500"
            persistent
            overlay-opacity="0.8"
        >
            <v-card>
                <v-toolbar color="grey darken-4" dark flat>
                    <v-toolbar-title>Project settings</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="onClose" color="white">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mt-4">
                    <form>
                        <v-text-field
                            v-model="title"
                            :error-messages="titleErrors"
                            label="Title"
                            required
                            @input="$v.title.$touch()"
                            @blur="$v.title.$touch()"
                        ></v-text-field>

                        <v-row>
                            <v-col md="10">
                                <v-text-field
                                    ref="publicLink"
                                    v-model="publicLink"
                                    label="Public link"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col md="2" class="justify-end">
                                <v-btn
                                    class="mt-3"
                                    outlined
                                    elevation="0"
                                    @click="handleCopyText"
                                >
                                    <v-icon>
                                        mdi-content-copy
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>

                <v-card-actions class="justify-space-between">
                    <v-btn
                        text
                        @click="onSaveAsTemplate"
                        outlined
                        elevation="0"
                    >
                        <v-icon left> mdi-content-save-all </v-icon>Save as
                        template
                    </v-btn>
                    <div>
                        <v-btn text @click="onDelete" outlined elevation="0">
                            <v-icon left> mdi-delete </v-icon>Delete
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="saved"
                            @click="onSave"
                            class="ml-2"
                            elevation="0"
                        >
                            <v-icon left> mdi-content-save </v-icon>Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="copied" :timeout="3000" color="primary">
            Link copied to clipboard
        </v-snackbar>
    </fragment>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import router from '../../router';
import { Fragment } from 'vue-fragment';

export default {
    name: 'ProjectSettings',
    components: {
        Fragment,
    },

    props: {
        isVisible: Boolean,
        id: {
            type: [String, null],
            default: '',
        },
    },
    mixins: [validationMixin],
    validations: {
        title: { required },
    },
    computed: {
        ...mapGetters('projects', ['getProjectById']),

        project() {
            const { projectId } = this.$route.params;
            return this.getProjectById(projectId);
        },

        titleErrors() {
            const errors = [];
            if (!this.$v.title.$dirty) return errors;
            // !this.$v.title.maxLength &&
            //     errors.push('Title must be at most 100 characters long');
            !this.$v.title.required && errors.push('Title is required.');
            return errors;
        },
    },

    mounted() {
        if (this.project) {
            this.title = this.project.title;
        }

        this.publicLink = window.location.href;
    },

    data: () => ({
        title: '',
        saved: true,
        publicLink: '',
        copied: true,
    }),

    methods: {
        ...mapMutations('projects', ['deleteProject']),
        ...mapMutations('markers', ['deleteAllProjectMarkers']),
        ...mapActions('projects', ['updateProject']),
        ...mapActions('templates', ['addTemplate']),

        onTitleChange(value) {
            this.title = value;
        },
        handleCopyText() {
            let textToCopy = this.$refs.publicLink.$el.querySelector('input');
            textToCopy.select();
            document.execCommand('copy');
            this.copied = true;
        },
        handleReset() {
            //var self = this;
            //this.title = '';
        },
        onClose() {
            // If not saved. Trigger are you sure?
            this.$emit('closed');
        },
        onSave() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                const { projectId } = this.$route.params;
                this.updateProject({ uuid: projectId, title: this.title });
                this.onClose();
            }
        },
        onSaveAsTemplate() {
            const { uuid, title, environment } = this.project;

            this.addTemplate({
                title,
                projectId: uuid,
                environment,
            });
        },
        onDelete() {
            const { projectId } = this.$route.params;
            this.deleteProject(projectId);
            this.deleteAllProjectMarkers(projectId);
            router.push(`/`);
        },
    },
    watch: {
        // id: function() {},
        title: {
            handler(newVal) {
                if (newVal !== this.project.title) {
                    this.saved = false;
                }
            },
        },
    },
};
</script>
