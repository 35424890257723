export const TemplateMarkerState = {
    namespaced: true,
    state: {
        markers: [],
    },

    mutations: {
        addAreaMarkers(state, payload) {
            console.log(payload);
            //state.markers = payload;
        },
        // edit
        // delete
    },
    actions: {
        addAreaMarkers({ commit, rootGetters }, payload) {
            const { uuid: templateId, projectId } = payload;

            const markers = rootGetters['markers/getAllMarkersByProject'](
                projectId,
            );

            const newMarkers = markers.map(obj => ({
                templateId,
                productId: null,
                ...obj,
            }));

            commit('addAreaMarkers', newMarkers);
        },
    },
    getters: {
        getTemplateMarkers: state => templateId => {
            return state.areaMarkers.filter(
                obj => obj.templateId === templateId,
            );
        },
    },
};
