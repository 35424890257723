<template>
  <v-row v-if="asset != null" no-gutters="" align="center" justify="center" style="position:relative">
    <v-col
    cols="5"
    v-if="asset.resources.length"
    ref="sideColumn"
    >
      <v-carousel
        v-model="carouselModel"
          cycle
          height="775px"
          :show-arrows="false"
          hide-delimiters=""
          :touchless="true"
          vertical
        >
          <v-carousel-item
            v-for="(resource, i) in imageResources"
            :key="i"
            style="position:relative"
          >
          <v-card width="100%" height="775px" tile class="d-flex" flat>
             <v-img style="position:absolute;" :src="resource.url" height="100%">
            </v-img>
          </v-card>
          </v-carousel-item>
        </v-carousel>
      Test Overview
    </v-col>
    <v-col cols="7">
      
      <div style="padding:60px;overflow-y:auto;max-height:775px;">
      <v-card flat color="transparent" class="pb-4" >
        <v-card class="mb-4" width="220px" height="140px" style="position:relative" flat color="transparent" v-if="showHeaderImage">
           <v-img
              :src="headerImage.url"
              height="140px"
              contain
              style="position:absolute;"
              />
        </v-card>
         <div v-if="typeData.useIcons" style="margin-bottom:-10px">
            <v-img :src="GetIconForResource(asset.resources[carouselModel])" height="80px" width="40px" contain/>
          </div>
         <h1 style="color:white;font-family:waHeadline;font-size:42px" class="mt-0 pt-0">
          {{asset.name}}
        </h1>
        <br/>
        <p style="color:white;font-family:waRegular;white-space: pre-line;font-size:20px;">
          {{asset.description}}
        </p>
      </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'AssetOverview',
    components: {

    },
    props: {

      asset : null,
      typeData : null,
      icons : Array

    },
    computed: {

      headerImage()
      {
        if(this.typeData == null)
        return null;

        var imageResourcesWithTag = this.asset.resources.filter(resource => resource.type == 0 && resource.tags.includes(this.typeData.headerImageTag));

        if(imageResourcesWithTag.length == 0)
        return null;
        else
        return imageResourcesWithTag[0];
      },
      showHeaderImage()
      {
        if(this.typeData == null)
          return false;
        else if(this.typeData.headerImageTag == null)
          return false;
        else if(this.headerImage == null)
          return false;
        else
          return true;
      },
      imageResources()
      {
        var overviewImages = this.asset.resources.filter(resource => resource.tags.includes("Overview"));

        if(overviewImages.length > 0)
        {
          return overviewImages;
        }
        var imageResources = this.asset.resources.filter(resource => resource.type == 0 && !resource.tags.includes("NoGallery"));
        var headerResource = this.headerImage;
        if(headerResource != null)
        {
          for (let i = 0; i < imageResources.length; i++) 
          {
            if(headerResource.id == imageResources[i].id)
            {
              imageResources.splice(i,1);
              break;
            }
          }
        }
        return imageResources;
      }
    },

    mounted () {

    },
    data: () => ({

      carouselModel : 0

    }),
    methods: {

      GetIconForResource(resource)
      {
        for (let index = 0; resource.tags.length; index++) {
          
          var matchingIcon = this.icons.filter(e => e.tag == resource.tags[index]);

          if(matchingIcon.length > 0)
          {
            return matchingIcon[0].url;
          }
        }

        return null;
      },



    },
    watch: {
      
    }
  }
</script>
